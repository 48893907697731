import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './BlockContainerWithBackground.module.css';

export interface BlockContainerWithBackgroundProperties {
  backgroundColor?:
    | 'blue'
    | 'gray'
    | 'lightGray'
    | 'white'
    | 'lightGrayBlue'
    | 'blueLightGray'
    | 'lightGrayGray'
    | 'lightGrayWhite'
    | 'blueGray'
    | 'blueWhite'
    | 'black'
    | string; // BD 10-05-2023: 'string' added because we had sanity codegen compatibility issues
  backgroundImage?: string;
  children: ReactNode;
  smallPaddingTop?: boolean;
  smallPaddingBottom?: boolean;
  noDesktopPaddingTopBottom?: boolean;
}

export const BlockContainerWithBackground = ({
  backgroundColor,
  backgroundImage,
  children,
  smallPaddingTop,
  smallPaddingBottom,
  noDesktopPaddingTopBottom,
}: BlockContainerWithBackgroundProperties) => {
  const className = cx(styles.container, {
    [styles.blue]: backgroundColor === 'blue',
    [styles.gray]: backgroundColor === 'gray',
    [styles.lightGray]: backgroundColor === 'lightGray',
    [styles.white]: backgroundColor === 'white',
    [styles.black]: backgroundColor === 'black',
    [styles.lightGrayBlue]: backgroundColor === 'lightGrayBlue',
    [styles.blueLightGray]: backgroundColor === 'blueLightGray',
    [styles.lightGrayGray]: backgroundColor === 'lightGrayGray',
    [styles.lightGrayWhite]: backgroundColor === 'lightGrayWhite',
    [styles.blueGray]: backgroundColor === 'blueGray',
    [styles.blueWhite]: backgroundColor === 'blueWhite',
    [styles.smallPaddingTop]: smallPaddingTop,
    [styles.smallPaddingBottom]: smallPaddingBottom,
    [styles.noDesktopPaddingTopBottom]: noDesktopPaddingTopBottom,
  });

  return (
    <div
      className={className}
      style={
        backgroundImage && backgroundImage !== ''
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : undefined
      }>
      {children}
    </div>
  );
};
